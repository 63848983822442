<template>
  <div>
    <!-- //底部导航栏 -->
    <van-tabbar v-model="active">
      <van-tabbar-item icon="home-o" to="/layout">首页</van-tabbar-item>
      <van-tabbar-item icon="location-o" to="/map"  @click="showPopup">地图
        
      </van-tabbar-item>
     
      <van-tabbar-item icon="phone-o" @click="goTel">电话 </van-tabbar-item>
    </van-tabbar>
    
  </div>
</template>

<script>
export default {
  name: "TabbarItem",
  data() {
    return {
      active: 0,
      show:false
    };
  },
  methods: {
    goTel() {
      window.location.href = "tel://18391021888";
    },
    showPopup(){
        this.show != this.show
    }
  },
};
</script>


<style>
.show{
    width: 100%;
    height: 100vh;
    background-color: pink;
    z-index: 99;
    /* position: absolute; */
}
</style>