import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    curItem:"",
    showCaritems:[
      {id:1,title:"旅游包车",carname:"商务车",serImage:'bussness1.png'},
      {id:2,title:"会议大巴服务",carname:"宇通19座",serImage:'bus6.jpg'},
      {id:3,title:"会议大巴服务",carname:"宇通50座",serImage:'bus7.jpg'},
      {id:4,title:"会议大巴服务",carname:"宇通56座",serImage:'bus11.jpg'},
      {id:5,title:"旅游包车",carname:"中巴外观",serImage:'bus4.jpg'},
      {id:6,title:"旅游包车",carname:"VIP38座",serImage:'bus38one.jpg'},
      {id:7,title:"旅游包车",carname:"旅游大巴",serImage:'bus50one.jpg'},
      {id:8,title:"旅游包车",carname:"帕萨特",serImage:'bussness2.png'},
      {id:9,title:"豪华轿车",carname:"豪华驾车",serImage:'top2.png'},
      {id:10,title:"豪华轿车",carname:"豪华驾车",serImage:'top1.png'},
      {id:11,title:"宇通中巴",carname:"中巴",serImage:'bus11.jpg'},
      {id:12,title:"宇通中巴",carname:"VIP38座",serImage:'bus38one.jpg'},
      {id:13,title:"宇通中巴",carname:"VIP38座",serImage:'bus38two.jpg'},
      {id:14,title:"宇通中巴",carname:"VIP38座",serImage:'bus38three.jpg'},
      {id:15,title:"豪华考斯特",carname:"中巴外观",serImage:'bus4.jpg'},
      {id:16,title:"豪华考斯特",carname:"中巴外观",serImage:'luxurybus.jpg'},
      {id:17,title:"别克商务",carname:"别克商务",serImage:'bussnessone.jpg'},
      {id:18,title:"别克商务",carname:"别克商务",serImage:'bussnesstwo.jpg'},
      {id:19,title:"别克商务",carname:"别克商务",serImage:'bussnessthree.jpg'},
      {id:20,title:"企业通勤服务",carname:"企业通勤服务",serImage:'bus38one.jpg'},
      {id:21,title:"企业通勤服务",carname:"企业通勤服务",serImage:'bus50one.jpg'},
      {id:22,title:"企业通勤服务",carname:"企业通勤服务",serImage:'bus56one.jpg'},




    ],


    showCaritemss: [
      {
        id: 1,
        title: "大巴车型展示",
        itemimage:"bus38one.jpg",

        caritems:[
            { id:1-1,
              name:"VIP38座",
              carimage:"bus38one.jpg"
            },
            { id:1-2,
              name:"宇通38座",
              carimage:"bus38five.jpg"
            },
            { id:1-3,
              name:"宇通50座",
              carimage:"bus50four.jpg"
            },
            { id:1-4,
              name:"宇通56座",
              carimage:"bus56one.jpg"
            },
        ]
      },
      { id: 2,
        title: "考斯特", 
        itemimage:"bus4.jpg",

        caritems:[
            { id:2-1,
              name:"秦旅38座",
              carimage:"bus4.jpg"
            },
        ]
        
      },
      {
        id: 3,
        title: "中巴车型展示",
        itemimage:"bus7.jpg",

        caritems:[
            { id:3-1,
              name:"秦旅38座",
              carimage:"bus4.jpg"
            },
        ]
      },
      {
        id: 4,
        title: "商务车型",
        itemimage:"bus11.jpg",

        caritems:[
            { id:4-1,
              name:"秦旅38座",
              carimage:"bus4.jpg"
            },
        ]
      },
      { id: 5,
        title: "舒适轿车",
        itemimage:"bus6.jpg",

        caritems:[
            { id:5-1,
              name:"秦旅38座",
              carimage:"bus4.jpg"
            },
        ]
      },
      { id: 6, 
        title: "高级轿车", 
        itemimage:"bus7.jpg",

        caritems:[
            { id:6-1,
              name:"秦旅38座",
              carimage:"bus4.jpg"
            },
        ]
      },
    ],
  },
  getters: {
    
  },
  mutations: {
    setCurItem(state,payload){
      state.curItem = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
