<template>
  <div id="app">
    <!-- //固定在顶部 -->
    <van-sticky>
      <top-tel/>
    </van-sticky>
    <top-intro v-show="$route.path =='/map'? false:true"></top-intro>
    <router-view/>
    <tabbar-item/>
    
  </div>
</template>

<script>
import TopIntro from './components/TopIntro.vue';
import TopTel from './views/Home/TopTel.vue';
//引入底部导航栏
import TabbarItem from './views/TabbarItem.vue';
export default {
  components: { TabbarItem ,TopTel,TopIntro},
  data(){
    return{
      showTop:true
      
    }
  }
 
  
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html{
  font-size: 100px;
  height: 100vh;
  /* background-color: aqua; */
}
body{
  font-size: .16rem;
}
</style>
