import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:"/layout"
    
  },
  
  {
    path: '/layout',
    name: 'Layout',
    component: () => import('../components/Layout.vue')
  },
 
  {
    path: '/map',
    name: 'Map',
    component: () => import('../views/map/LocalMap.vue')
  },
  {
    path: '/tel',
    name: 'Tel',
    component: () => import('../views/Home/TopTel.vue')
  },
  {
    path: '/nav',
    name: 'Nav',
    component: () => import('../views/Home/NavList.vue')
  },
  {
    path: '/car',
    name: 'Car',
    component: () => import('../views/Home/CarSwiper.vue')
  },
  {
    path: '/server',
    name: 'Server',
    component: () => import('../views/ServerItem/ServeItem.vue')
  },
  {
    path: '/rent',
    name: 'Rent',
    component: () => import('../views/CarRent/CarRent.vue')
  },
  {
    path: '/show',
    name: 'Show',
    component: () => import('../views/CarShow/CarShow.vue')
  },
  {
    path: '/footer',
    name: 'Footer',
    component: () => import('../components/AFooter.vue')
  },
  {
    path: '/about',
    name: 'IntroUs',
    component: () => import('../pages/IntroUs.vue')
  },
  {
    path: '/connect',
    name: 'ConnectOur',
    component: () => import('../pages/ConnectOur.vue')
  },
  {
    path: '/rents',
    name: 'RentNeed',
    component: () => import('../pages/RentNeed.vue')
  },
  {
    path: '/glory',
    name: 'ComGlory',
    component: () => import('../pages/ComGlory.vue')
  },
  {
    path:"/LicsDetail/:id",
    component:()=>import("../pages/LicsDetail.vue")
  },
  {
    path:"/ser",
    component:()=>import("../pages/splitpages/SplitPages.vue")
  },
  {
    path:"/cartype",
    component:()=>import("../pages/showcartype/ShowType.vue")
  },
  {
    path:"/cartype/:id",
    component:()=>import("../pages/showcartype/ShowDetail.vue")
  },
  {
    path:"/splitpages/detail",
    component:()=>import("../pages/splitpages/BusDetail.vue")
  },
  {
    path:"/test",
    component:()=>import("../components/ComTest.vue")
  }
  
]

const router = new VueRouter({
  routes
})

export default router
