import Vue from "vue"

import {Button,Search,Empty,Toast,Icon,Tag,Tabbar,Collapse,CollapseItem, TabbarItem,Popup,Cell,Sticky,Swipe, SwipeItem} from "vant"

Vue.use(Button);
Vue.use(Icon);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Cell);
Vue.use(Popup);
Vue.use(Sticky);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tag)
Vue.use(Collapse)
Vue.use(CollapseItem);
Vue.use(Search);
Vue.use(Toast);
Vue.use(Empty)
