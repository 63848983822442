<template>
<div>

  <div class="top-tel">
    <div class="firstpage" @click="shownav">
      
    </div>
    
    <div class="consult">咨询电话: <a href="tel:15319779737">15319779737</a> <a href="tel:18391021888">18391021888</a></div>
    <div class="firstsearch" @click="showSearch"></div>
    
  </div>
  <div class="topSearch" v-show="searchShow">
    <van-search
          v-model="value"
          show-action
          placeholder="请输入搜索关键词"
          @search.prevent="onSearch"
        >
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
    </van-search>
  </div>
   

   
     <ul class="navLists navposition" v-show="navListShow">
      <li><a href="">网站首页</a></li>
      <li><router-link to="/ser" >服务项目</router-link></li>
      <li><router-link to="/about" >关于秦旅</router-link></li>

      <li><router-link to="/rents" >租车须知</router-link></li>
      <li><router-link to="/glory">资质荣誉</router-link></li>
      <li><router-link to="/cartype/1">车型展示</router-link></li>
      <li><router-link to="/about" >关于我们</router-link></li>
      <li><router-link to="/connect">联系我们</router-link></li>

    </ul>
  
</div>
</template>

<script>
export default {
  data(){
    return{
      navListShow:false,
      searchShow:false,
      value:"",
      spos:""
    }
  },
  methods:{
    shownav(){
      this.navListShow = !this.navListShow
    },
    showSearch(){
      this.searchShow = !this.searchShow
    },
    onSearch(){

      let searchItem = this.value;
      let allSItems = this.$store.state.showCaritemss;
      var flag = true
      for(let i=0;i<allSItems.length;i++){
        
        if(allSItems[i].title === searchItem){
          flag = false
          this.$router.push("/cartype/"+(i+1));
          this.searchShow = !this.searchShow
          break;
        }
      }
      if(flag){
      
          this.value = ""
          this.$toast('没有找到您想要的内容,再看看吧,有惊喜');    
        }
      this.searchShow = !this.searchShow
    }
  }
};
</script>

<style scoped lang="scss">
.topSearch{
  position: absolute;
  width: 100%;
  top: -.08rem;
  left: 0;
}
.navposition{
  position: absolute;
  top: .17rem;
}
.navLists{
    background-color: white;
    padding: 1.5%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-top: .1rem;
    justify-content: space-around;
    li{
        width: 22.7%;
        box-sizing: border-box;
        margin-top: 1%;
        line-height: .43rem;
        height: .43rem;
        font-size: .15rem;
        text-align: center;
        border: 1px solid #ddd;
        border-radius: 4px;
        &:hover{
          background-color: black;
          a{color: white;}
        }
        
        a{
            color: black;
        }
    }
    

}
.top-tel {
  display: flex;
  justify-content: space-between;
  padding: 1.5% .15rem;
  background-color: white;
  .firstpage {
    background: url("../../assets/firstpageImg/firstpage.png") center center
      no-repeat;
    width: 0.16rem;
    height: 0.16rem;
    background-size: 0.16rem;
  }
  .firstsearch{
      background: url("../../assets/firstpageImg/firstsearch.png") center center
      no-repeat;
    width: 0.16rem;
    height: 0.16rem;
    background-size: 0.16rem;
  }
  .consult{
      font-size: .12rem;
      line-height: .16rem;
      a{
          color: black;
      }
  }
}
</style>