<template>
  <div class="layout">
    <div class="firstpageImage">
      <img src="../assets/firstpageImg/ico.png" alt="">
    </div>
    <b>丰富的经验 | 贴心的服务</b>
    <p>专业旅游汽车运输</p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.layout{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  
  .firstpageImage{
    width: 55%;
    height: 10vh;
    margin-bottom: .03rem;
    img{
      width: 100%;
      display: block;
    }
  }
  b{
    color: #040404;
    font-size: .12rem;
    font-weight: normal;
    margin-top: .04rem;
    padding-bottom: .08rem;
  }
  p{
    color: #ed1301;
    font-size: .12rem;
  }
}
</style>